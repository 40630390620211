import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import cross from '../assets/img/icons/cross-white.svg'
import { useTranslation } from 'react-i18next'
import { changeLanguage, generateLink } from '../utils/i18n';



const CookieBanner = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const [isVisible, setIsVisible] = useState(!cookies.cookieConsent);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const { t } = useTranslation()
  useEffect(() => {
    const handleRouteChange = () => {
      if (window.location.pathname !== currentPath) {
        setCurrentPath(window.location.pathname);
        if (!cookies.cookieConsent) {
          setIsVisible(true); 
        }
      }
    };

    window.addEventListener('popstate', handleRouteChange);
    const intervalId = setInterval(handleRouteChange, 100);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
      clearInterval(intervalId);
    };
  }, [currentPath, cookies.cookieConsent]);

  const handleAccept = () => {
    setCookie('cookieConsent', true, { path: '/', maxAge: 31536000 }); // 1 год
    setIsVisible(false);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className='cookieBanner'>
      <p className='cookieText'>
        {t('general.cookie_text')}
        <a href={generateLink('/terms-and-conditions')} className='cookieLink'>
            {t('general.footer_menu3')}
        </a>.
      </p>
      <div className='cookieBtns'>
        <button className='cookieBtn btn btn_primary' onClick={handleAccept}>
        {t('general.accept')}
        </button>
        <button className='cookieClose' onClick={handleClose}>
            <img src={cross} alt="Close" />
        </button>
      </div>
    </div>
  );
};

const styles = {
  banner: {
    position: "fixed",
    bottom: "0",
    left: "0",
    width: "100%",
    backgroundColor: "#f8f9fa",
    padding: "20px",
    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    margin: 0,
    fontSize: "14px",
    lineHeight: "1.5",
  },
  link: {
    color: "#007bff",
    textDecoration: "none",
  },
  buttons: {
    display: "flex",
    gap: "10px",
  },
  acceptButton: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  closeButton: {
    backgroundColor: "transparent",
    border: "none",
    fontSize: "18px",
    cursor: "pointer",
  },
};

export default CookieBanner;
