import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import store from './store/store';
import { useCookies } from 'react-cookie';
import { router } from './routes/routes';
import { I18nextProvider } from 'react-i18next';
import i18n, { changeLanguage } from './utils/i18n';
import { ToastContainer } from 'react-toastify'
import OfflinePage from './components/OfflinePage';
import FormLoader from './ui/FormLoader';
import CookieBanner from './components/CookieBanner';


const App = () => {
  const [cookies] = useCookies(['language']);
  const language = cookies.language || 'en';
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const [loadingTranslations, setLoadingTranslations] = useState(true);


  useEffect(() => {
    const loadTranslations = async () => {
      await changeLanguage(language);
      setLoadingTranslations(false); 
    };

    loadTranslations();
  }, [language]);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (isOffline) {
    return <OfflinePage />;
  }

  if (loadingTranslations) {
    return <FormLoader />; 
  }

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={router} />
        <ToastContainer />
        <CookieBanner /> 
      </I18nextProvider>
    </Provider>
  );
}

export default App;
